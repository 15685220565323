import { makeObservable, observable } from "mobx";
import { store } from ".";
import { User, TimeSlot } from '../components/models/';
import { IUser } from "../components/models/User";
import { doFetch } from '../utils';

const URLS = {
  GET_USERS: '/api/gatesettings/users',
  ADD_USER: '/api/gatesettings/users',
  VALIDATE_USER: '/api/gatesettings/users/validate',
  UPDATE_USER: (userID: string) => `/api/gatesettings/users/${userID}`,
  DELETE_USER: (userID: string) => `/api/gatesettings/users/${userID}`	,
  ADD_TIME_SLOT: (userID: string) => `/api/gatesettings/${userID}/timeslot`,
  UPDATE_TIME_SLOT: () => ``,
  DELETE_TIME_SLOT: (userID: string, timeSlotID: string) => `/api/gatesettings/${userID}/timeslot/${timeSlotID}`,
}

class UserStore extends store {
  newUser: User
  newTimeSlot: TimeSlot;
  selectedUser: User | undefined
  users: User[]

  constructor() {
    super();
    this.users = [];
    this.newUser = new User(undefined);
    this.newTimeSlot = new TimeSlot(undefined);
    this.selectedUser = undefined;

    makeObservable(this, {
      users: observable,
      newUser: observable,
      selectedUser: observable,
      newTimeSlot: observable,
    })
  }

  validateUser = async (user: User): Promise<string[]> => {
    const { data } = await doFetch(URLS.VALIDATE_USER, 'POST', user) as { data: string[]};

    return data
  };

  updateUser = async () => {
    if (this.selectedUser === undefined) {
      throw new Error('Unable to update user, the selected user is still undefined.')
    }

    const { id } = this.selectedUser;

    const { status } = await doFetch(URLS.UPDATE_USER(id), 'PUT', this.selectedUser);

    if (status !== 200) {
      throw new Error('Er is iets fout gegaan tijdens het aanpassen van het geselecteerde profiel.')
    }
  };

  addUser = async () => {
    const { data: user, status } = await doFetch(URLS.ADD_USER, 'POST', this.newUser) as { status: number, data: IUser };

    if (status !== 200) {
      throw new Error('Er is iets fout gegaan tijdens het toevoegen van het nieuwe profiel.')
    }

    this.users.push(new User(user));
    this.newUser = new User(undefined);
  };

  // getUsers fetches all the users in the system.
  getUsers = async () => {
    const { status, data: users } = await doFetch(URLS.GET_USERS, 'GET');

    if (status !== 200) {
      throw new Error('Er is iets fout gegaan bij het inladen van de profielen. Probeer het later opnieuw...')
    }

    this.users = users.map((user: IUser) => new User(user))
  };

  // addTimeSlot adds the new time slot to the selected user.
  addTimeSlot = async (user: User) => {
    const { start, end } = this.newTimeSlot;
    if (start === "") {
      throw new Error("Start cannot be empty")
    }

    if (end === "") {
      throw new Error("End cannot be empty")
    }

    user.timeSlots.push(this.newTimeSlot)
    this.newTimeSlot = new TimeSlot(undefined);
  }

  // deleteTimeSlot deletes the given timeslot from the selected user.
  deleteTimeSlot = async (user: User, timeSlotID: string) => {
    user.timeSlots = user.timeSlots.filter((timeSlot: TimeSlot) => {
      console.log({
        id: timeSlot.id,
        timeslot_id: timeSlotID
      })

      return timeSlot.id !== timeSlotID;
    });
  };

  deleteUser = async (user: User) => {
    await doFetch(URLS.DELETE_USER(user.id), 'DELETE', {})

    this.users = this.users.filter(({ id }) => {
      return id !== user.id;
    })
  };

}

export default new UserStore();
