import { useState } from 'react';
import { observer } from "mobx-react"
import { ArrowLeft, X } from 'react-feather';
import {motion, AnimatePresence} from 'framer-motion';

import { Modal, Button } from '../elements/';
import { modalStore, toastStore, userStore } from '../../stores/';
import { UserForm } from '../forms/';
import { TimeSlots } from '../partials';
import { controlOnErrors } from '../../utils';

import styles from '../../assets/scss/addUser.module.scss';

const AddUserModal = observer(() => {
  const { newUser, newTimeSlot } = userStore;
  const [ step, setStep ] = useState(1)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ errors, setErrors ] = useState<string[]>([])

  const close = () => {
    modalStore.closeModal('addUser');
  };

  const addUser = async () => {
    try {
      if (newTimeSlot.start !== '' && newUser !== undefined) {
        await userStore.addTimeSlot(newUser);
      }

      await userStore.addUser()
      toastStore.success("De nieuwe gebruiker is succesvol aangemaakt.")
      close();
    } catch (err) {
      console.log(err)
    }
  };

  const proceed = async () => {
    try {
      await controlOnErrors(newUser)
      const errors = await userStore.validateUser(newUser);
      if (errors.length !== 0) {
        setErrors(errors)
        return
      }
      setErrors([]);
      setStep(2);
    } catch (err) {
      console.log(err)
    }
  };

  const onClose = () => {
    setStep(1);
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true)
  };

  const goBack = () => {
    setStep(1);
  };

  return (
    <Modal id="addUser" onClose={onClose} onOpen={onOpen}>
      <>
        <div className="modal-header">
          <h1 className="title">Nieuwe gebruiker - Stap {step} van 2</h1>

          <X onClick={close}/>
        </div>

        <div className="modal-content">
          <AnimatePresence exitBeforeEnter>
            {
              step === 1 && (
                <motion.div 
                  key="step1"
                  transition={{
                    bounce: 0
                  }}
                  animate={{ opacity: 1, y: 0, x: 0, scale: 1 }} 
                  initial={
                    !isOpen 
                      ? { opacity: 1, y: "-50vh", x: 0, scale: 1 }
                      : { opacity: 1, y: 0, x: "-50vw", scale: 1 }
                  } 
                  exit={{ opacity: 1, x: "-50vw", scale: 1 }} 
                  style={{padding: 0, margin: 0}}
                >

                  {
                    errors.length >0 && (
                      <div className={styles.warning}>
                        <ul>
                          {
                            errors.map(error => (
                              <li>{error}</li>
                            ))
                          }
                        </ul>
                      </div>
                    )
                  }
                  <UserForm user={newUser} />
                </motion.div>
              )
            }

            {
              step === 2 && (
                <>
                  <motion.div 
                    transition={{
                      bounce: 0
                    }}
                    key="step2"
                    animate={{ opacity: 1, y: 0, x: 0, scale: 1 }} 
                    initial={{ opacity: 1, y: 0, x: "100vw", scale: 1 }} 
                    exit={{ opacity: 1, x: "100vw", scale: 1 }} 
                    style={{padding: 0, margin: 0}}
                  >
                    <div 
                      className={styles.goBack} 
                      onClick={goBack}
                    >
                      <ArrowLeft />
                      <span>
                        Ga naar de vorige stap
                      </span>
                    </div>

                    <TimeSlots user={newUser} />
                  </motion.div>
                </>
              )
            }
          </AnimatePresence>
        </div>

        <div className="modal-footer">
          {
            step === 1 && (
              <Button text="Ga naar de volgende stap" onClick={proceed}/>
            )
          }

          {
            step === 2 && (
              <>
                <Button text="Voeg de gebruiker toe" onClick={addUser}/>
              </>
            )
          }
        </div>
      </>
    </Modal>
  )
})

export default AddUserModal 
