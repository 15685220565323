import { observer } from "mobx-react"
import { X } from 'react-feather';

import { Modal, Button } from '../elements/';
import { modalStore, toastStore, userStore } from '../../stores/';

const DeleteUserModal = observer(() => {
  const { selectedUser } = userStore;

  const close = () => {
    modalStore.closeModal('deleteUser');
  };

  const deleteUser = async () => {
    try {
      if (selectedUser === undefined) {
        return
      }

      await userStore.deleteUser(selectedUser);
      toastStore.success(`${selectedUser.name} is succesvol verwijderd.`)
      close();
    } catch (err) {
      if (selectedUser === undefined) {
        return
      }

      toastStore.error(`Er is iets mis gegaan tijdens het verwijderen van ${selectedUser.name}.`);
    }
  };

  return (
    <Modal id="deleteUser">
      <>
        <div className="modal-header">
          <h1 className="title">Verwijder {selectedUser?.name}</h1>
          <X onClick={close}/>
        </div>

        <div className="modal-content">
          Bent u zeker dat u {selectedUser?.name} wenst te verwijderen? Deze actie kan niet worden teruggedraaid.
        </div>

        <div className="modal-footer">
          <Button text="Verwijder de gebruiker" onClick={deleteUser}/>
        </div>
      </>
    </Modal>
  )
})

export default DeleteUserModal 
