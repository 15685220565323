import { useEffect } from "react";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import { LogOut } from 'react-feather';

import { globalStore, logStore } from '../../stores';
import { Input } from '../elements';

import styles from '../../assets/scss/logs.module.scss'

const Logs = observer(() => {
  const { logs, timeSettings } = logStore;

  useEffect(() => {
    logStore.getLogs();
  }, []);

  const logout = () => {
    globalStore.logout();
  };

  return (
    <main>
      <h1 className="title">
        Logs 
      </h1>

      <div className={styles.cta} >
        <div className={styles.ctaBtn} onClick={logout}>
          <LogOut />
          <span>Logout</span>
        </div>
      </div>


      <div className={styles.filterContainer}>
        <div>
          <label>Bekijk de geschiedenis van</label>
          <Input entity={timeSettings} name="start" type="datetime-local" />
        </div>
        <div>
          <label>Tot</label>
          <Input entity={timeSettings} name="stop" type="datetime-local" />
        </div>
      </div>

      {
        logs.length ===  0 && (
          <div className="placeholder">
            <span style={{marginRight: '5px'}}>
              Er is geen geschiedenis in het systeem aanwezig voor de periode van &nbsp; 
            {dayjs(timeSettings.start).format('MM/DD/YYYY - HH:MM')} tot {dayjs(timeSettings.stop).format('MM/DD/YYYY - HH:MM')}.
            </span>
          </div>
        )
      }

      {
      logs.length !== 0 && (
        <ul>
          {
            logs.map(log => (
              <li key={log.id} className={styles.log}>
                <span>
                  [{dayjs.unix(log.time).format('HH:mm - DD/MM/YYYY')}]
                </span>

                <span>
                  {log.msg}
                </span>
              </li>
            ))
          }
        </ul>
      )
      }
      
    </main>
  )
})

export default Logs;
