import { observable, makeObservable } from 'mobx';
import Model from './Model'

export interface ILog {
  id: string,
  msg: string,
  time: number,
}

class Log extends Model {
  id: string
  msg: string
  time: number


  constructor(data: ILog | undefined) {
    super();
    this.id = data?.id || '';
    this.msg = data?.msg || '';
    this.time = data?.time || 0;

    makeObservable(this, {
      id: observable,
      msg: observable,
      time: observable,
    })
  }
}

export default Log
