import { observer } from "mobx-react";
import { Link, useLocation } from 'react-router-dom';
import { Users, BarChart2 } from 'react-feather';

import styles from '../../assets/scss/footer.module.scss';

const Footer = observer(() => {
  const { pathname } = useLocation();

  const isActive = (path: string) => {
    return pathname.includes(path) ? styles.active : undefined;
  };

  return (
    <footer className={styles.footer}>
      <ul className={styles.links}>
        <li className={isActive('/users')}>
          <Link to="/users">
            <Users />
            <span>
              Profielen
            </span>
          </Link>
        </li>
        <li className={isActive('/logs')}>
          <Link to="/logs">
            <BarChart2 />
            <span>
              Logs
            </span>
          </Link>
        </li>
      </ul>
    </footer>
    
  )
})

export default Footer;

