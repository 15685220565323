import { observer } from "mobx-react"
import { Modal, Button } from '../elements/';
import { modalStore, toastStore, userStore } from '../../stores';
import { TimeSlots } from '../partials';

const EditTimesModal = observer(() => {
  const { selectedUser, newTimeSlot } = userStore;
  const { name = [] } = selectedUser || {}

  const close = () => {
    modalStore.closeModal('editTimes');
  };

  const update = async () => {
    try {
      if (newTimeSlot.start !== '' && selectedUser !== undefined) {
        await userStore.addTimeSlot(selectedUser);
      }

      await userStore.updateUser();
      toastStore.success(`De tijdslots van ${selectedUser?.name} zijn succesvol aangepast.`)
      close()
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal id="editTimes" className="double">
      <>
        <div className="modal-header">
          <h1 className="title">
            Toegangsmomenten - { name }
          </h1>

          <i className="fas fa-times" onClick={close} />
        </div>

        <div className="modal-content">
          {
            selectedUser !== undefined && (
              <TimeSlots user={selectedUser} />
            )
          }
        </div>

        <div className="modal-footer">
          <Button text="Sla wijzigingen op" onClick={update}/>
          <Button text="Sluit" onClick={close}/>
        </div>
      </>
    </Modal>
  )
})

export default EditTimesModal 
