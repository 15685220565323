import { useState } from 'react';
import { observer } from "mobx-react"
import { Modal, Button } from '../elements/';
import { UserForm } from '../forms/';
import { modalStore, toastStore, userStore } from '../../stores';
import { controlOnErrors } from '../../utils';

import styles from '../../assets/scss/editUser.module.scss';

const EditUserModal = observer(() => {
  const { selectedUser } = userStore;
  const [ errors, setErrors ] = useState<string[]>([])

  const close = () => {
    modalStore.closeModal('editUser');
  };

  const editUser = async () => {
    if (selectedUser !== undefined) {
      try {
        await controlOnErrors(selectedUser)
        const errors = await userStore.validateUser(selectedUser);
        if (errors.length !== 0) {
          setErrors(errors)
          return
        }
        setErrors([]);
        await userStore.updateUser();
        toastStore.success("De geselecteerde gebruiker is succesvol aangepast.")
        close()
      } catch(err) {
        console.log(err)
      }
    }
  };

  return (
    <Modal id="editUser" className="double">
      <>
        <div className="modal-header">
          <h1 className="title">
            { selectedUser?.name }
          </h1>

          <i className="fas fa-times" onClick={close}/>
        </div>

        <div className="modal-content">
          {
            errors.length >0 && (
              <div className={styles.warning}>
                <ul>
                  {
                    errors.map(error => (
                      <li>{error}</li>
                    ))
                  }
                </ul>
              </div>

            )
          }
          <UserForm user={selectedUser} />
        </div>

        <div className="modal-footer">
          <Button text="Pas de gegevens aan" onClick={editUser}/>
          <Button text="Sluit" onClick={close}/>
        </div>
      </>
    </Modal>
  )
})

export default EditUserModal 
