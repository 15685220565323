import { store } from ".";
import { doFetch } from '../utils';

const URLS = {
  LOGOUT: `/engelendael/auth/logout`,
}

class CodeStore extends store {
  logout = () => {
    return doFetch(URLS.LOGOUT, 'POST');
  }
}

export default new CodeStore();
