import Model from './components/models/Model';
import { extendObservable } from 'mobx';

class Validator {
  static input = (message: string) => {
    return (input: any) => {
      return {
        message,
        error: input === "",
      }
    }
  }

  static number = (message: string) => {
    return (input: number) => {
      return {
        message,
        error: Number.isNaN(input) || input < 10000,
      }
    }
  }

  static initialiseValidation = (model: Model, validation: {[key: string]: Function}) => {

    const keys = Object.keys(validation);
    const obj = {} as {[key: string]: {message: string, error: Boolean}};

    keys.forEach((key) => {
      obj[`error${key}`] = {
        message: '',
        error: false
      };
    });

    extendObservable(model, obj);
  };
}

export default Validator;
