import { observable, makeObservable } from 'mobx';
import Model from './Model'
import dayjs from "dayjs";

class TimeSetting extends Model {
  start: string;
  stop: string;


  constructor() {
    super();
    this.start = dayjs().subtract(7, "d").format('YYYY-MM-DDTHH:mm');
    this.stop = dayjs().add(1, "h").format('YYYY-MM-DDTHH:mm');

    makeObservable(this, {
      start: observable,
      stop: observable,
    })
  }
}

export default TimeSetting;
