import { observer } from 'mobx-react';
import { AnimatePresence } from "framer-motion";
import Router from './components/Router';
import { toastStore } from './stores';
import { Toast } from './components/elements/';

const Toasts = observer(() => {
  const { toasts } = toastStore;

  return (
    <AnimatePresence>
      {
        toasts.map((toast, idx) => (
          <Toast 
            toast={toast} 
            key={toast.id} 
            idx={idx} 
          />
        ))
      }  
    </AnimatePresence>
  )
});

const App = () => {
  return (
    <>
      <Toasts />
      <Router />
    </>
  );
}

export default App;


