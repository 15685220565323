import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { userStore, modalStore, globalStore } from '../../stores';
import { Plus, LogOut, Clock, Edit, Grid, Trash2, Key, Search } from 'react-feather';
import User, { State } from '../models/User';
import { EditUserModal, AddUserModal, EditTimesModal, DeleteUserModal } from '../modals';

import styles from '../../assets/scss/users.module.scss';

const Users = observer(() => {
  const { users } = userStore;
  const [ filter, setFilter ] = useState('')

  useEffect(() => {
    userStore.getUsers();
  }, [])

  const logout = () => {
    globalStore.logout();
  };

  const editUser = (user: User) => {
    userStore.selectedUser = user;
    modalStore.openModal('editUser');
  };

  const updateFilter = (ev: any) => {
    setFilter(ev.target.value);
  };

  const deleteUser = (user: User) => {
    userStore.selectedUser = user;
    modalStore.openModal('deleteUser');
  };

  const editTimes = (user: User) => {
    userStore.selectedUser = user;
    modalStore.openModal('editTimes');
  };

  const openAddUserModal = () => {
    modalStore.openModal('addUser');
  };

  const usersToRender = users.filter(user => {
    const str = JSON.stringify(user);

    if (filter === '') {
      return true
    }

    return str.toLowerCase().includes(filter.toLowerCase());
  })

  return (
    <>
      <EditUserModal />
      <EditTimesModal />
      <AddUserModal />
      <DeleteUserModal />

      <main>
        <div className="content">
          <h1 className="title">
            Profielen
          </h1>

          <div className={styles.cta} >
            <div className={styles.ctaBtn} onClick={openAddUserModal}>
              <Plus />
              <span>
                Nieuw profiel
              </span>
            </div>
            <div className={styles.ctaBtn} onClick={logout}>
              <LogOut />
              <span>Logout</span>
            </div>
          </div>

          <div className={styles.searchInput}>
            <input placeholder="Zoek op naam, code, wachtwoord,.." value={filter} onChange={updateFilter}/>
            <Search />
          </div>

          {
            users.length === 0 && filter === '' && (
              <>
                <div className="placeholder">
                  Momenteel zijn er nog geen profielen in het systeem, voeg een profiel toe via de knop "Nieuw profiel".
                </div>
              </>
            )
          }

          {
            usersToRender.length === 0 && filter !== '' && (
              <div className="placeholder">
                Er zijn geen profielen gevonden die overeenkomen met de opgegeven zoekterm.
              </div>
            )
          }

          

          {
            usersToRender.length > 0 && (
              <>
                <ul className={styles.users}>
                  {
                    usersToRender.map((user: User) => {
                      let activeStyle = undefined;

                      switch(user.state) {
                        case State.OK:
                          activeStyle = styles.online;
                          break;

                        case State.EXPIRED:
                          activeStyle = styles.expired;
                          break;

                        case State.NOT_CONFIGURED:
                          activeStyle = styles.not_configured;
                          break;
                      }


                      return (
                        <li key={user.id} className={styles.user}>

                          <div className={activeStyle} />

                          <span className={styles.name}>{user.name}</span>
                          <span className={styles.passphrase}>
                            <Key />
                            {user.passphrase}
                          </span>
                          <span className={styles.code}>
                            <Grid />
                            {user.code}
                          </span>

                          <div className={styles.actions}>
                            <Clock onClick={() => editTimes(user)} />
                            <Edit onClick={() => editUser(user)} />
                            <Trash2 onClick={() => deleteUser(user)}/>
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>  
              </>
            )
          }
        </div>
      </main>  
    </>
  )
})

export default Users
