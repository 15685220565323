import { observer } from "mobx-react";
import { Input } from '../elements/';

const UserForm = observer(({ user }: { user: any }) => {
  return (
    <>
      <div>
        <label>Naam van de gebruiker</label>
        <Input entity={user} name="name" placeholder="Catering x..." />
      </div>  

      <div>
        <label>Wachtwoord voor de gebruiker</label>
        <Input entity={user} name="passphrase" placeholder="DitIsEenWachtwoord..." />
      </div>

      <div>
        <label>Code voor de gebruiker</label>
        <Input type="number" entity={user} name="code" placeholder="123456" />
      </div>
    </>
  )
})

export default UserForm;
