import jwt_decode from "jwt-decode";
import { Model } from "./components/models";

export const controlOnErrors = (entity: Model) => {
  return new Promise((resolve, reject) => {
    let hasErrors = false
    const keys = Object.keys(entity.validation)

    keys.forEach((key: string) => {
      const valueToValidate = entity[key as keyof typeof entity] 
      const errFunc = entity.validation[key]

      if (errFunc !== undefined) {
        const result = errFunc(valueToValidate)
        if (result.error) {
          hasErrors = true;
        }

        entity[`error${key}` as keyof typeof entity] = result;
      }
      console.log(valueToValidate)
    })

    if (hasErrors) {
      reject()
    }

    resolve(true);
  })
}

export const doFetch = async (url: string, method: string, data?: any): Promise<{status: number, data: any}>  => {
  const response = await fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
    },
    body: method !== 'GET' ? JSON.stringify(data) : undefined // body data type must match "Content-Type" header
  });

  const parseData = await response.json();

  if (response.status === 401) {
    window.location.href = 'https://sso.engelendael.be';
  }

  return {
    status: response.status,
    data: parseData
  }
}

export const getCookieValue = (): { authenticated: boolean, expiring: number } => {
  try {
    const token = document.cookie.replace("engelendael-token=", "")
    const decoded = jwt_decode(token) as { authenticated: boolean, expiring: number };
    return decoded
  } catch (err) {
    console.log(err)
    return {
      authenticated: false,
      expiring: 0
    }
  }
}

