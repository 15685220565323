import { makeObservable, observable, observe } from "mobx";
import { store } from ".";
import { Log, TimeSettings } from '../components/models/';
import { ILog } from '../components/models/Log';
import { doFetch } from '../utils';
import dayjs from 'dayjs';

const URLS = {
  GET_EVENTS_BETWEEN_TIME: (start: number, stop: number) => `/api/gatesettings/events/${start}/${stop}`
}

class logStore extends store {
  selectedLog: Log;
  logs: Log[];
  timeSettings: TimeSettings; 

  constructor() {
    super()
    this.selectedLog = new Log(undefined);
    this.logs = [];
    this.timeSettings = new TimeSettings();

    observe(this.timeSettings, 'start', this.getLogs)
    observe(this.timeSettings, 'stop', this.getLogs)

    makeObservable(this, {
      selectedLog: observable,
      logs: observable
    });

    // setInterval(this.getLogs, 2000)
  }

  getLogs = async () => {
    const { start, stop } = this.timeSettings;
    const { data: logs } = await doFetch( URLS.GET_EVENTS_BETWEEN_TIME(dayjs(start).unix(), dayjs(stop).unix()), 'GET')

    this.logs = logs.map((log: ILog) => new Log(log))
  }
}

export default new logStore();
