import { observable, makeObservable } from 'mobx';
import TimeSlot, { ITimeSlot } from './TimeSlot';
import Model from './Model'
import dayjs from "dayjs";
import validator from '../../Validator';

export enum State {
  OK = 'OK',
  EXPIRED = 'EXPIRED',
  NOT_CONFIGURED = 'NOT_CONFIGURED',
}

export interface IUser {
  passphrase: string,
  id: string,
  code: string,
  name: string,
  time_slots: ITimeSlot[],
}

class User extends Model {
  passphrase: string
  id: string
  code: string
  name: string
  timeSlots: TimeSlot[]
  state: State

  constructor(data: IUser | undefined) {
    super();
    this.passphrase = data?.passphrase || '';
    this.id = data?.id || '';
    this.code = data?.code || '';
    this.name = data?.name || '';
    this.timeSlots = [];

    if (data !== undefined && data.time_slots.length > 0) {
      this.timeSlots = data.time_slots.map(timeSlot => new TimeSlot(timeSlot));
    }

    let state = State.NOT_CONFIGURED
    if (this.timeSlots.length > 0) {
      state = State.EXPIRED;

      this.timeSlots.forEach(timeSlot => {
        const unix = Date.now() / 1000;

        if (dayjs(timeSlot.start).unix() < unix && dayjs(timeSlot.end).unix() > unix) {
          state = State.OK;
        }
      });
    }

    this.state = state;


    this.validation = {
      'passphrase': validator.input("Vul een geldig wachtwoord in"),
      'name': validator.input("Vul een geldige naam in"),
      'code': validator.number("Vul een code van 6 nummers in"),
    };

    if (data?.time_slots !== undefined) {
      this.timeSlots = data.time_slots.map((timeSlot) => {
        return new TimeSlot(timeSlot)
      })
    };

    makeObservable(this, {
      passphrase: observable,
      id: observable,
      code: observable,
      name: observable,
      timeSlots: observable,
    });

    validator.initialiseValidation(this, this.validation)
  };

  toJSON = () => {
    return {
      id: this.id,
      passphrase: this.passphrase,
      name: this.name,
      code: this.code,
      time_slots: this.timeSlots,
    }
  }
};

export default User 
