import { observer } from 'mobx-react';

import { userStore } from '../../stores';
import { TimeSlot, User } from "../models";
import { Input } from '../elements/';
import { Plus, Trash2  } from 'react-feather';
import styles from '../../assets/scss/users.module.scss'

const TimeSlots = observer(({ user }: { user: User }) => {
  const { newTimeSlot } = userStore;
  const { timeSlots = [] } = user || {}

  const addTimeSlot = async () => {
    try {
      await userStore.addTimeSlot(user)
    } catch (err) {
      console.log(err)
    }
  };

  const deleteTimeSlot = async (timeSlot: TimeSlot) => {
     try {
      await userStore.deleteTimeSlot(user, timeSlot.id)
    } catch (err) {
      console.log(err)
    }
  };

  const onEnter = (e: any) => {
    if( e.key === 'Enter' ){
      addTimeSlot()
    }
  };

  return (
    <>
      {
        timeSlots.length === 0 && (
          <div className="placeholder">
            Let op: Er zijn geen toegangsmomenten gevonden voor {user?.name}
          </div>
        )
      }

      {
        timeSlots.length !== 0 && (
          <ul className={styles.timeSlots} onKeyDown={onEnter}>
            {
              timeSlots.map(timeslot => (
                <li className={styles.timeslot} key={timeslot.id}>
                  <Input entity={timeslot} name="start" type="datetime-local" />
                  <Input entity={timeslot} name="end" type="datetime-local"/>
                  <div className={styles.deleteBtn} onClick={() => deleteTimeSlot(timeslot)}>
                    <Trash2 />
                  </div>
                </li>
              ))
            }
          </ul>
        )
      }

      <div className={styles.timeslot}>
        <Input entity={newTimeSlot} name="start" type="datetime-local" />
        <Input entity={newTimeSlot} name="end" type="datetime-local"/>
        <div className={styles.addBtn} onClick={addTimeSlot}>
          <Plus />
        </div>
      </div>
    </>
  )
});

export default TimeSlots
