import { observer } from "mobx-react";

const Dashboard = observer(() => {
  return (
    <main>
      <h1 className="title">
        Dashboard
      </h1>
    </main>
  )
})

export default Dashboard
