import { useState, useEffect, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { modalStore } from '../../stores';
import styles from '../../assets/scss/modal.module.scss';
import { motion, AnimatePresence } from "framer-motion"


interface props {
  id: string,
  className?: string,
  onClose?: () => void,
  onOpen?: () => void,
  children?: ReactElement<any, any>,
}

const Modal = observer(({id, className, onClose, onOpen, children }: props) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ init, setInit ] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else if (!isOpen) {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }
  }, [isOpen])

  useEffect(() => {
    if (init) {
      if (isOpen && onOpen !== undefined) {
        onOpen();
      }

      if (!isOpen && onClose !== undefined) {
        onClose();
      }
    } else {
      setInit(true);
    }
  }, [isOpen, onOpen, onClose, init])

  useEffect(() => {
    modalStore.registerModal({
      id,
      cb: (isOpen: boolean) => {
        setIsOpen(isOpen)
      }
    });

    return () => {
      modalStore.unregisterModal(id);
    }
  }, [id]);


  return (
    <AnimatePresence>
      {
        isOpen && (
          <>
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              id={id} 
              key={id}
              className={`${styles.modal} ${className === "double" ? styles.double : className} `}>
              {children}
            </motion.div>  
            <div 
              className={styles.overlay} 
              onClick={() => setIsOpen(false)} 
              style={{ zIndex: id === 'warningModal' ? 15004 : 1500 }}
            />
          </>
        )
      }
    </AnimatePresence>
  )
});

export default Modal;


