import { makeObservable, observable } from "mobx";
import { getCookieValue } from '../utils';

class GlobalStore {
  searchTerm: string;

  constructor() {
    this.searchTerm = ''

    makeObservable(this, {
      searchTerm: observable,
    });

    setInterval(() => {
      const cookie = getCookieValue();
      if (!cookie.authenticated || cookie.expiring < Date.now() / 1000) {
        window.location.href = 'https://sso.engelendael.be';
      }
    }, 5000);
  }

  logout = () => {
    const host = new URL(window.location.href).hostname;
    const domain =
      (host === "localhost" || (host.match(/\./g) || []).length <= 1)
        ? host
        : host.substr(host.indexOf(".") + 1, host.length);

        const cookieName = "engelendael-token";
        const cookieValue = "";
        const myDate = "Thu, 01 Jan 1970 00:00:01 GMT";

    document.cookie = `${cookieName}=${cookieValue};expires=${myDate};domain=${domain};path=/`;
    window.location.href = 'https://sso.engelendael.be';
  };
}

export default new GlobalStore();
