import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Users, BarChart2 } from 'react-feather';

import engelendaelLogoDark from '../../assets/images/logo.svg'
import styles from '../../assets/scss/aside.module.scss';

const Sidenav = observer(() => {
  const { pathname } = useLocation();

  const isActive = (path: string) => {
    return pathname.includes(path) ? styles.active : undefined;
  };

  return (
    <aside>
      <div className={styles.info}>
        <div className={styles.logo}>
          <Link to="/">
            <img src={engelendaelLogoDark} alt="Engelendael logo" />
          </Link>
        </div>
      </div>


      <ul className={styles.links}>
        <li className={isActive('/users')}>
          <Link to="/users">
            <Users />
            <span>
              Profielen
            </span>
          </Link>
        </li>
        <li className={isActive('/logs')}>
          <Link to="/logs">
            <BarChart2 />
            <span>
              Logs
            </span>
          </Link>
        </li>
      </ul>
    </aside>
  )
});

export default Sidenav;


