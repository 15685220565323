import { observable, makeObservable } from 'mobx';
import { Toast } from '../components/models';

class ToastStore {
  toasts: Toast[];

  constructor() {
    this.toasts = [];


    makeObservable(this, {
      toasts: observable,
    })
  }

  removeToast = (toastID: string) => {
    this.toasts = this.toasts.filter(({ id }) => id !== toastID);
  }

  success = (message: string, replacements?: {[key: string]: string}) => {
    const toast = new Toast(message, 'success');
    this.toasts.push(toast);

    setTimeout(() => {
      this.toasts = this.toasts.filter(({ id }) => id !== toast.id);
    }, 4000);
  };

  error = (message: string, replacements?: {[key: string]: string}) => {
    const toast = new Toast(message, 'error');
    this.toasts.push(toast);

    setTimeout(() => {
      this.toasts = this.toasts.filter(({ id }) => id !== toast.id);
    }, 4000);
  };
}

export default new ToastStore();


