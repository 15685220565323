class Model {
  // validation: {[key: string]: (input: any) => {
  //   error: boolean, message: string
  // }}
  validation: any

  constructor() {
    this.validation = {}
  }
}

export default Model

