import { observable, makeObservable } from 'mobx';
import Model from './Model'
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';

export interface ITimeSlot {
  id: string,
  start: number
  end:number 
  user: string
}

class TimeSlot extends Model {
  id: string
  start: string
  end: string
  user: string


  constructor(data: ITimeSlot | undefined) {
    super();
    this.id = data?.id || uuidv4();
    this.user = data?.user || '';
    this.start = ''
    this.end = ''

    if (data !== undefined && data.start !== undefined) {
      this.start = dayjs.unix(data.start).format('YYYY-MM-DDTHH:mm')
    }

    if (data !== undefined && data.end !== undefined) {
      this.end = dayjs.unix(data.end).format('YYYY-MM-DDTHH:mm')
    }

    makeObservable(this, {
      id: observable,
      user: observable,
      start: observable,
      end: observable,
    })
  }

  toJSON = () => {
    return {
      id: this.id,
      user: this.user,
      start: dayjs(this.start).unix(),
      end: dayjs(this.end).unix(),
    }
  }
}

export default TimeSlot 
