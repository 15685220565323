import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Users, Logs, Dashboard } from './pages'
import { Aside, Footer } from './partials';

const Router = () => {
  return (
    <BrowserRouter>
      <Aside />
      <Routes>
        <Route path="/dashboard" element={<Dashboard />}/>
        <Route path="/users" element={<Users />}/>
        <Route path="/logs" element={<Logs />}/>
        <Route path="*" element={<Navigate to="/users" />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default Router;

