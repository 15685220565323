import React, { useState } from 'react';
import { observer } from 'mobx-react-lite'
import styles from '../../assets/scss/dropdown.module.scss';

const Dropdown = observer(({ children, className }: { children: any, className?: string}) => {
  const [state, setState] = useState(false)

  const toggleState = (ev: any) => {
    ev.stopPropagation();
    setState(!state);
  };

  const close = (ev: any) => {
    ev.stopPropagation();
    setState(false)
  };

  return (
    <div className={`${styles.dropdownContainer} ${className}`}>
      <div className={`${styles.dropdown} dropdown`} onClick={toggleState}>
        <i className="fas fa-ellipsis-h" />
      </div>

      {
        state && (
          <>
            <ul className={styles.dropdownList} onClick={close}>
              {children}
            </ul>
            <div className={styles.dropdownOverlay} onClick={close}/>
          </>
        )
      }
    </div>
  )
});

export default Dropdown;


