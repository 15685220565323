import { v4 as uuidv4 } from 'uuid';

class Toast {
  id: string;
  message: string;
  type: string;
  replacements: {[key: string]: string}

  constructor(message: string, type: string, replacements?: {[key: string]: string}) {
    this.id = uuidv4();
    this.message = message;
    this.type = type;
    this.replacements = replacements ?? {}
  }
}

export default Toast;


